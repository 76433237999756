import type { ComponentType } from "react"

export const withCopyToClipboard = (Component): ComponentType => {
    return (props) => {
        const textToCopy = "sales@cashflow.io"

        const handleClick = () => {
            navigator.clipboard.writeText(textToCopy)
        }

        return <Component {...props} onClick={handleClick} />
    }
}
